module.exports = {
  offset_account: {
    id: 117,
    coa_id: 2,
    type: 'asset',
    subtype: 'cash_equivalent',
    title: 'Bank account #1',
    description: null,
    code: 1901,
    default_active_percentage: null,
    group_id: 33,
    global_id: 46,
    is_importable: 1,
    importable_default_parser: null,
    importable_type: 'bank_account',
    default_offset_account_id: 138,
    status: 'active',
    in_bucket: 'conservative',
    is_bucket_free_capital: 1,
    allow_update: 0,
    created_at: '2020-10-28T06:26:11.000000Z',
    updated_at: '2020-10-28T06:26:11.000000Z',
    title_with_code: '1901 - Bank account #1',
    account_group: {
      id: 33,
      coa_id: 2,
      type: 'asset',
      title: 'Bank accounts',
      description: null,
      code: 1900,
      global_id: 11,
      created_at: '2020-10-28T06:26:11.000000Z',
      updated_at: '2020-10-28T06:26:11.000000Z'
    }
  },
  import_id: 44,
  parse_result: {
    parser_class_name: 'IcaBankenSwedenParser',
    parser_nice_name: 'bankparser.ica_banken.normal_parser.name',
    totally_successful_parse: true,
    success_rate: 100,
    entries_total: 4,
    entries_passed: 4,
    entries_failed: 0,
    first_transaction_date: '2019-03-31',
    last_transaction_date: '2019-03-31',
    raw_data_string: '2019-03-31  Swish Ikonen Raija  Insättning  Övrigt  600,00 kr   21 170,07 kr\n2019-03-31  Swish Ikonen Raija  Insättning  Övrigt  1 780,00 kr 20 570,07 kr\n2019-03-31  Swish Mia Persson   Uttag   Övrigt  -80,00 kr   18 790,07 kr\n2019-03-31  Kreditavgift Mars   Övrigt  Övrigt  -16,99 kr   18 870,07 kr',
    error_message: null,
    log: [
      'Loaded rules and historical transactions in 0.15756106376648 seconds.',
      'No parser supplied, will test all',
      [
        'Tried: AmericanExpressEurobonusParser, Success rate: 0%',
        'Tried: ArbejdernesLandsbankDenmarkParser, Success rate: 0%',
        'Tried: AvanzaSwedenParser, Success rate: 0%',
        'Tried: BetterGlobeParser, Success rate: 0%',
        'Tried: CircleKParser, Success rate: 0%',
        'Tried: CoopMedMeraSwedenParser, Success rate: 0%',
        'Tried: DanskeBankParser, Success rate: 0%',
        'Tried: EdenredRikskupongSwedenParser, Success rate: 0%',
        'Tried: EverydayCardSwedenParser, Success rate: 0%',
        'Tried: FirstcardSwedenParser, Success rate: 0%',
        'Tried: ForexBankSwedenParser, Success rate: 0%',
        'Tried: HandelsbankenParser, Success rate: 0%',
        'Tried: IcaBankenSwedenMagicParser, Success rate: 100%',
        'Result with 100 % success_rate found. Exiting loop through parsers'
      ],
      'Parse of 4 transactions finished. Passed: 4. Failed: 0. Finished in 0.11294293403625 seconds. ',
      'Made 1 suggestions to 4 transactions (25 %). Finished in: 0.23494696617126 seconds.',
      'Found 0 duplicates and 0 duplicate transfers. Finished in 0.013561010360718 seconds.'
    ]
  },
  account_statement: '2019-03-31  Swish Ikonen Raija  Insättning  Övrigt  600,00 kr   21 170,07 kr\n2019-03-31  Swish Ikonen Raija  Insättning  Övrigt  1 780,00 kr 20 570,07 kr\n2019-03-31  Swish Mia Persson   Uttag   Övrigt  -80,00 kr   18 790,07 kr\n2019-03-31  Kreditavgift Mars   Övrigt  Övrigt  -16,99 kr   18 870,07 kr',
  parsed_account_statement: {
    with_parse_ok: [
      {
        title: 'Swish Ikonen Raija',
        date: '2019-03-31',
        amount: '600.00',
        type: 'income',
        raw_string: '2019-03-31\tSwish Ikonen Raija\tInsättning\tÖvrigt\t600.00\t21170.07',
        is_identified_as_transfer: null,
        is_identified_as_transfer_type: null,
        balance: '21170.07',
        transaction_type: 'Insättning',
        transaction_category: 'Övrigt',
        is_potential_duplicate: 0,
        is_potential_duplicate_transfer: 0,
        suggested_accounts: {
          primary_suggestion_account_id: null,
          suggestions: [],
          logic: [],
          log: [
            'Trying to match user rules.',
            'Trying to match user historical transactions.',
            'Trying to match global rules since no suggestions have been found yet.',
            'Trying to match all global transactions from all users matched to own accounts.'
          ]
        }
      },
      {
        title: 'Swish Ikonen Raija',
        date: '2019-03-31',
        amount: '1780.00',
        type: 'income',
        raw_string: '2019-03-31\tSwish Ikonen Raija\tInsättning\tÖvrigt\t1780.00\t20570.07',
        is_identified_as_transfer: null,
        is_identified_as_transfer_type: null,
        balance: '20570.07',
        transaction_type: 'Insättning',
        transaction_category: 'Övrigt',
        is_potential_duplicate: 0,
        is_potential_duplicate_transfer: 0,
        suggested_accounts: {
          primary_suggestion_account_id: null,
          suggestions: [],
          logic: [],
          log: [
            'Trying to match user rules.',
            'Trying to match user historical transactions.',
            'Trying to match global rules since no suggestions have been found yet.',
            'Trying to match all global transactions from all users matched to own accounts.'
          ]
        }
      },
      {
        title: 'Swish Mia Persson',
        date: '2019-03-31',
        amount: '80.00',
        type: 'expense',
        raw_string: '2019-03-31\tSwish Mia Persson\tUttag\tÖvrigt\t-80.00\t18790.07',
        is_identified_as_transfer: null,
        is_identified_as_transfer_type: null,
        balance: '18790.07',
        transaction_type: 'Uttag',
        transaction_category: 'Övrigt',
        is_potential_duplicate: 0,
        is_potential_duplicate_transfer: 0,
        suggested_accounts: {
          primary_suggestion_account_id: null,
          suggestions: [],
          logic: [],
          log: [
            'Trying to match user rules.',
            'Trying to match user historical transactions.',
            'Trying to match global rules since no suggestions have been found yet.',
            'Trying to match all global transactions from all users matched to own accounts.'
          ]
        }
      },
      {
        title: 'Kreditavgift Mars',
        date: '2019-03-31',
        amount: '16.99',
        type: 'expense',
        raw_string: '2019-03-31\tKreditavgift Mars\tÖvrigt\tÖvrigt\t-16.99\t18870.07',
        is_identified_as_transfer: null,
        is_identified_as_transfer_type: null,
        balance: '18870.07',
        transaction_type: 'Övrigt',
        transaction_category: 'Övrigt',
        is_potential_duplicate: 0,
        is_potential_duplicate_transfer: 0,
        suggested_accounts: {
          primary_suggestion_account_id: 115,
          suggestions: {
            115: {
              account_id: 115,
              title: 'Other and unidentified expenses',
              default_active_percentage: 0,
              transaction_type: 'expense'
            }
          },
          logic: {
            115: {
              points: 100,
              account_id: 115,
              account_title: 'Other and unidentified expenses',
              account_title_with_code: '5902 - Other and unidentified expenses',
              default_active_percentage: 0,
              transaction_type: 'expense',
              matched_rules: {
                global_rules: [
                  {
                    id: 32,
                    coa_id: 1,
                    rule_title: null,
                    title_operator: 'contains',
                    title_value: 'Avgift',
                    amount_operator: 'is_any',
                    amount_value: null,
                    account_id: 44,
                    percentage: 0,
                    transaction_type: 'expense',
                    description: null,
                    order: 32,
                    is_active: 1,
                    locale: 'sv-SE',
                    created_at: '2020-10-28T06:29:21.000000Z',
                    updated_at: '2020-10-28T06:29:21.000000Z',
                    account: {
                      id: 44,
                      coa_id: 1,
                      type: 'expense',
                      subtype: null,
                      title: 'global.accounts.expenses.misc_misc',
                      description: null,
                      code: 5902,
                      default_active_percentage: 0,
                      group_id: 10,
                      global_id: null,
                      is_importable: 0,
                      importable_default_parser: null,
                      importable_type: null,
                      default_offset_account_id: null,
                      status: 'active',
                      in_bucket: null,
                      is_bucket_free_capital: 1,
                      allow_update: 0,
                      created_at: '2020-10-28T06:26:10.000000Z',
                      updated_at: '2020-10-28T06:26:10.000000Z',
                      title_with_code: '5902 - global.accounts.expenses.misc_misc'
                    }
                  }
                ]
              }
            }
          },
          log: [
            'Trying to match user rules.',
            'Trying to match user historical transactions.',
            'Trying to match global rules since no suggestions have been found yet.',
            'Matched global rule 32 to user account (115): Other and unidentified expenses',
            'Ignoring global historical transactions since suggestions are already given.'
          ]
        }
      }
    ],
    with_parse_error: []
  },
  addTransaction: [],
  user_transactions: [],
  transactions: [
    {
      title: 'Swish Ikonen Raija',
      date: '2019-03-31',
      amount: 600,
      raw_string: '2019-03-31\tSwish Ikonen Raija\tInsättning\tÖvrigt\t600.00\t21170.07',
      balance: '21170.07',
      transaction_type: 'Insättning',
      transaction_category: 'Övrigt',
      duplicate: 0,
      duplicate_transfer: 0,
      suggestion_account_id: -1,
      type: 'income',
      accounts: null,
      percentage: 0,
      is_duplicate: false,
      is_duplicate_transfer: false
    },
    {
      title: 'Swish Ikonen Raija',
      date: '2019-03-31',
      amount: 1780,
      raw_string: '2019-03-31\tSwish Ikonen Raija\tInsättning\tÖvrigt\t1780.00\t20570.07',
      balance: '20570.07',
      transaction_type: 'Insättning',
      transaction_category: 'Övrigt',
      duplicate: 0,
      duplicate_transfer: 0,
      suggestion_account_id: -1,
      type: 'income',
      accounts: null,
      percentage: 0,
      is_duplicate: false,
      is_duplicate_transfer: false
    },
    {
      title: 'Swish Mia Persson',
      date: '2019-03-31',
      amount: 80,
      raw_string: '2019-03-31\tSwish Mia Persson\tUttag\tÖvrigt\t-80.00\t18790.07',
      balance: '18790.07',
      transaction_type: 'Uttag',
      transaction_category: 'Övrigt',
      duplicate: 0,
      duplicate_transfer: 0,
      suggestion_account_id: -1,
      type: 'expense',
      accounts: null,
      percentage: 0,
      is_duplicate: false,
      is_duplicate_transfer: false
    },
    {
      title: 'Kreditavgift Mars',
      date: '2019-03-31',
      amount: 16.99,
      raw_string: '2019-03-31\tKreditavgift Mars\tÖvrigt\tÖvrigt\t-16.99\t18870.07',
      balance: '18870.07',
      transaction_type: 'Övrigt',
      transaction_category: 'Övrigt',
      duplicate: 0,
      duplicate_transfer: 0,
      suggestion_account_id: 115,
      type: 'expense',
      accounts: null,
      percentage: 0,
      is_duplicate: false,
      is_duplicate_transfer: false
    }
  ],
  transactions_error: [],
  parsed_transactions: [
    {
      title: 'Swish Ikonen Raija',
      date: '2019-03-31',
      amount: '600.00',
      type: 'income',
      raw_string: '2019-03-31\tSwish Ikonen Raija\tInsättning\tÖvrigt\t600.00\t21170.07',
      is_identified_as_transfer: null,
      is_identified_as_transfer_type: null,
      balance: '21170.07',
      transaction_type: 'Insättning',
      transaction_category: 'Övrigt',
      is_potential_duplicate: 0,
      is_potential_duplicate_transfer: 0,
      suggested_accounts: {
        primary_suggestion_account_id: null,
        suggestions: [],
        logic: [],
        log: [
          'Trying to match user rules.',
          'Trying to match user historical transactions.',
          'Trying to match global rules since no suggestions have been found yet.',
          'Trying to match all global transactions from all users matched to own accounts.'
        ]
      }
    },
    {
      title: 'Swish Ikonen Raija',
      date: '2019-03-31',
      amount: '1780.00',
      type: 'income',
      raw_string: '2019-03-31\tSwish Ikonen Raija\tInsättning\tÖvrigt\t1780.00\t20570.07',
      is_identified_as_transfer: null,
      is_identified_as_transfer_type: null,
      balance: '20570.07',
      transaction_type: 'Insättning',
      transaction_category: 'Övrigt',
      is_potential_duplicate: 0,
      is_potential_duplicate_transfer: 0,
      suggested_accounts: {
        primary_suggestion_account_id: null,
        suggestions: [],
        logic: [],
        log: [
          'Trying to match user rules.',
          'Trying to match user historical transactions.',
          'Trying to match global rules since no suggestions have been found yet.',
          'Trying to match all global transactions from all users matched to own accounts.'
        ]
      }
    },
    {
      title: 'Swish Mia Persson',
      date: '2019-03-31',
      amount: '80.00',
      type: 'expense',
      raw_string: '2019-03-31\tSwish Mia Persson\tUttag\tÖvrigt\t-80.00\t18790.07',
      is_identified_as_transfer: null,
      is_identified_as_transfer_type: null,
      balance: '18790.07',
      transaction_type: 'Uttag',
      transaction_category: 'Övrigt',
      is_potential_duplicate: 0,
      is_potential_duplicate_transfer: 0,
      suggested_accounts: {
        primary_suggestion_account_id: null,
        suggestions: [],
        logic: [],
        log: [
          'Trying to match user rules.',
          'Trying to match user historical transactions.',
          'Trying to match global rules since no suggestions have been found yet.',
          'Trying to match all global transactions from all users matched to own accounts.'
        ]
      }
    },
    {
      title: 'Kreditavgift Mars',
      date: '2019-03-31',
      amount: '16.99',
      type: 'expense',
      raw_string: '2019-03-31\tKreditavgift Mars\tÖvrigt\tÖvrigt\t-16.99\t18870.07',
      is_identified_as_transfer: null,
      is_identified_as_transfer_type: null,
      balance: '18870.07',
      transaction_type: 'Övrigt',
      transaction_category: 'Övrigt',
      is_potential_duplicate: 0,
      is_potential_duplicate_transfer: 0,
      suggested_accounts: {
        primary_suggestion_account_id: 115,
        suggestions: {
          115: {
            account_id: 115,
            title: 'Other and unidentified expenses',
            default_active_percentage: 0,
            transaction_type: 'expense'
          }
        },
        logic: {
          115: {
            points: 100,
            account_id: 115,
            account_title: 'Other and unidentified expenses',
            account_title_with_code: '5902 - Other and unidentified expenses',
            default_active_percentage: 0,
            transaction_type: 'expense',
            matched_rules: {
              global_rules: [
                {
                  id: 32,
                  coa_id: 1,
                  rule_title: null,
                  title_operator: 'contains',
                  title_value: 'Avgift',
                  amount_operator: 'is_any',
                  amount_value: null,
                  account_id: 44,
                  percentage: 0,
                  transaction_type: 'expense',
                  description: null,
                  order: 32,
                  is_active: 1,
                  locale: 'sv-SE',
                  created_at: '2020-10-28T06:29:21.000000Z',
                  updated_at: '2020-10-28T06:29:21.000000Z',
                  account: {
                    id: 44,
                    coa_id: 1,
                    type: 'expense',
                    subtype: null,
                    title: 'global.accounts.expenses.misc_misc',
                    description: null,
                    code: 5902,
                    default_active_percentage: 0,
                    group_id: 10,
                    global_id: null,
                    is_importable: 0,
                    importable_default_parser: null,
                    importable_type: null,
                    default_offset_account_id: null,
                    status: 'active',
                    in_bucket: null,
                    is_bucket_free_capital: 1,
                    allow_update: 0,
                    created_at: '2020-10-28T06:26:10.000000Z',
                    updated_at: '2020-10-28T06:26:10.000000Z',
                    title_with_code: '5902 - global.accounts.expenses.misc_misc'
                  }
                }
              ]
            }
          }
        },
        log: [
          'Trying to match user rules.',
          'Trying to match user historical transactions.',
          'Trying to match global rules since no suggestions have been found yet.',
          'Matched global rule 32 to user account (115): Other and unidentified expenses',
          'Ignoring global historical transactions since suggestions are already given.'
        ]
      }
    }
  ]
}
