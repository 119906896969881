<template>
    <b-container class="dashboad bg-light page-wrapper">
        <b-row class="main-content-wrapper" no-gutters>
            <b-col cols="12">
                <h3> Import Transaction Component</h3>
            </b-col>
            <b-col cols="12">
                <import-transaction-table
                  :parsedTransactions="sample"
                  :detailRow="true"
                  :tab="tabConfig"
                  @delete-transactions="updateInitialData"
                  @add-transaction="updateInitialData"
                  @update-transactions="updateInitialData"
                />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import ImportTransactionTable from '../../transactions/import-account-statement/component/ImportTransactionTable'
import * as ImportSampleData from './SampleData/ImportSampleData'

export default {
  name: 'ImportExperiment',
  components: {
    ImportTransactionTable
  },
  data () {
    return {
      sample: [],
      stepper: 0.5,
      tabConfig: {
        info: true,
        split: true,
        additional: true,
        raw: true
      },
      inputData: null
    }
  },
  created () {
    this.sample = ImportSampleData
  },
  methods: {
    updateInitialData (values) {
      this.sample = Object.assign({}, this.sample, values)
    }
  }
}
</script>
