<template>
  <div>
    <slot name="massEditForm">
      <!--mass edit component-->
      <mass-edit-import-form ref="MassEditImportForm" :offsetAccountName="offsetAccountName" :currentUser="currentUser" :currentCOA="currentCOA" @save-changes="handleUpdateTransactions"/>
    </slot>
    <b-row class="mb-3" no-gutters>
      <b-col>
        <b-button
          variant="outline-danger"
          class="btn-sm mr-2 float-right header-btn"
          @click="$refs.deleteImport.show(true)"
          v-show="!disabledButtons"
        >
          <i class="flaticon solid trash-3"></i> {{ $t("common.mass_delete") }}
        </b-button>
        <!--@click=""-->
        <b-button
          variant="primary"
          class="btn-sm mr-2 float-right header-btn"
          @click="$refs.MassEditImportForm.show()"
          v-show="!disabledButtons"
        >
          <i class="flaticon solid edit-3"></i> {{ $t("common.mass_edit") }}
        </b-button>
        <slot name="headerButtons"></slot>
      </b-col>
    </b-row>
    <b-table
      :items="transactions"
      :fields="fieldValues"
      :filter="transaction_filter"
      :per-page="TransactionPerPage"
      :current-page="TransactionCurrentPage"
      stacked="lg"
      select-mode="multi"
      :emptyText="$t(translationPath + 'table.no_data')"
      @filtered="onFilteredTransactionAccounts"
      @row-hovered="rowHoveredHandler"
      @row-unhovered="rowUnHoveredHandler"
      :tbody-tr-class="rowClass"
      :busy="busyState"
      ref="transactionTableView"
      class="spirecta-importable-table"
      show-empty
      hover
      responsive
      striped
    >
      <!--loader-->
      <template v-slot:table-busy>
        <loader />
      </template>
      <!--loader ends here-->
      <template slot="top-row">
        <td colspan="10">
          <div class="d-flex align-items-center">
            <i class="fa fa-search"></i>
            <b-form-input
              v-model="transaction_filter"
              size="sm"
              :placeholder="$t(translationPath + 'table.filter_text')"
              @input="resetSelectedRows"
            />
          </div>
        </td>
      </template>
      <!--details arrow-->
      <template v-slot:cell(show_details)="row">
        <div class="showDetailsMain" @click="row.toggleDetails">
          <i
            class="fa fa-caret-down displayHidden"
            :ref="'toggleRow_' + row.index"
            >&nbsp;</i
          >
          <i :class="'fa fa-caret-' + (row.detailsShowing ? 'down' : 'right')"
            >&nbsp;</i
          >
          <span class="d-inline d-lg-none">{{
            $t("reports.other.ledger.report.table.show_more_info")
          }}</span>
        </div>
      </template>
      <!--details row-->
      <template v-slot:row-details="row">
        <b-card no-body class="details-card">
          <b-tabs cards class="transparent-tabs">
            <information-tab
              :accountOptions="accountOptions"
              :percentageOptions="percentageOptions"
              :offsetAccountName="offsetAccountName"
              :transactionData="row.item"
              @success-submit="value => handleUpdateTabTransaction(value, row)"
              v-if="tab.info === true"
            />
            <raw-data-tab
              v-bind:rawString="row.item.raw_string"
              v-if="tab.raw === true"
            />
            <split-tab
              v-bind:currentUser="currentUser"
              v-bind:currentCOA="currentCOA"
              v-bind:transactionAmount="row.item.amount"
              v-bind:transactionDate="row.item.date"
              v-bind:transactionTitle="row.item.title"
              v-bind:transactionType="row.item.type"
              @save="removeAndAddTrans"
              @cancel-clicked="row.toggleDetails"
              v-if="tab.split === true"
            />
            <additional-tab v-if="tab.additional === true" />
            <!--template slot for adding tabs-->
            <template slot="tabSlot"></template>
          </b-tabs>
          <b-button
            variant="outline-light"
            class="tab-close"
            @click="row.toggleDetails"
            ><b-icon-x
          /></b-button>
        </b-card>
      </template>
      <!--checkbox--->
      <template v-slot:head(checkbox)>
        <b-form-checkbox
          v-model="allSelected"
          :indeterminate="indeterminate"
          @change="toggleAll"
        ></b-form-checkbox>
      </template>
      <template v-slot:cell(checkbox)="row">
        <b-form-checkbox-group v-model="selected">
          <b-form-checkbox
            :value="row.item"
            @change="values => selectCheckBoxRow(values, row.index)"
          ></b-form-checkbox>
          <!--:disabled="disableSingleRows.indexOf(row.item.index) == -1 && !row.item.suggestion"-->
        </b-form-checkbox-group>
      </template>
      <template v-slot:cell(date)="row">
        <template v-if="enableEditingDetails.indexOf(row.item.index) > -1">
          <b-form-group
            :state="
              row.item.date
                ? $moment(row.item.date).isValid()
                  ? true
                  : false
                : false
            "
            :invalid-feedback="
              row.item.date
                ? $moment(row.item.date).isValid()
                  ? ''
                  : $t(translationPath + 'form.errors.invalid_date')
                : $t(translationPath + 'form.errors.required_error')
            "
            class="mb-0"
          >
            <datepicker
              v-model="row.item.date"
              :format="dateFormatter"
              v-bind:input-class="{
                'form-conrol is-invalid':
                  !row.item.date && !$moment(row.item.date).isValid(),
                'form-control is-valid':
                  row.item.date && $moment(row.item.date).isValid()
              }"
              :language="selectedLang"
              :monday-first="startWeekByMonday"
            ></datepicker>
          </b-form-group>
        </template>
        <template v-else>
          {{ row.value }}
          <div
            class="notify"
            v-if="row.item.is_duplicate || row.item.is_duplicate_transfer"
          >
            <b-badge
              v-if="row.item.is_duplicate"
              variant="warning"
              class="mr-1 cursor-help"
            >
              <b-link
                class="badge-sidebar-link"
                v-b-toggle.sidebar_is_duplicate
              >
                {{ $t(translationPath + "badge_duplicate") }} (?)</b-link
              >
            </b-badge>
            <b-badge
              v-if="row.item.is_duplicate_transfer"
              variant="warning"
              class="mr-1 cursor-help"
            >
              <b-link
                class="badge-sidebar-link"
                v-b-toggle.sidebar_is_duplicate_transfer
              >
                {{
                  $t(translationPath + "badge_duplicate_transfer")
                }}
                (?)</b-link
              >
            </b-badge>
          </div>
        </template>
      </template>
      <template v-slot:cell(title)="row">
        <template v-if="enableEditingDetails.indexOf(row.item.index) > -1">
          <b-form-group
            :state="!row.item.title || row.item.title.length < 2 ? false : true"
            :invalid-feedback="
              !row.item.title
                ? $t(translationPath + 'form.errors.required_error')
                : row.item.title.length < 2
                ? $t(translationPath + 'form.errors.minlength_error')
                : ''
            "
            class="mb-0"
          >
            <b-form-input
              type="text"
              v-model.trim="row.item.title"
              :state="
                !row.item.title || row.item.title.length < 2 ? false : true
              "
            ></b-form-input>
          </b-form-group>
        </template>
        <template v-else>
          <div class="titleValue">
            <div class="titleText mr-2">{{ row.value }}</div>
            <div class="titleButtonWrapper">
              <div class="titleButton">
                <div class="titleAction">
                  [
                  <b-link href="#" @click="enableEdit(row)">
                    <i
                      class="flaticon solid edit-3 text-gray"
                      :title="$t('common.edit')"
                    ></i>
                    <!--{{ $t('common.edit')}}-->
                  </b-link>
                  |
                </div>
                <div class="titleAction">
                  <b-link href="#" @click="row.toggleDetails">
                    <i
                      class="flaticon stroke info-2 text-gray"
                      :title="$t('common.toggle_more_info')"
                    ></i>
                    <!--{{$t('common.delete')}}-->
                  </b-link>
                  |
                </div>
                <div class="titleAction">
                  <b-link href="#" @click="openSingleModal(row)">
                    <i
                      class="flaticon solid trash-3 text-gray"
                      :title="$t('common.delete')"
                    ></i>
                    <!--{{$t('common.delete')}}-->
                  </b-link>
                  ]
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>

      <!-- TYPES column -->
      <template v-slot:cell(types)="row">
        <template v-if="disableSingleRows.indexOf(row.item.index) == -1">
          <b-form-select
            v-model="row.item.type"
            :state="row.item.type ? true : false"
            @change="() => (row.item.accounts = null)"
            :options="transactionTypeOptions"
            class="transaction_type_select"
          />
        </template>
        <template v-else>{{ $t("common." + row.item.type) }}</template>
      </template>

      <!-- ACCOUNT CELL -->
      <template v-slot:cell(account)="row">
        <template
          v-if="disableSingleRows.indexOf(row.item.index) == -1"
          class="tableMultiselectDiv"
        >
          <b-form-group
            :state="row.item.accounts ? true : false"
            :invalid-feedback="
              !row.item.accounts
                ? $t(translationPath + 'form.errors.select_acccount_category')
                : ''
            "
            class="mb-0"
          >
            <table-custom-multiselect
              :placeHolder="placeHolder"
              :searchPlaceholder="searchPlaceholder"
              :noResultText="noResultText"
              :accounttype="row.item.type"
              :initialValue="optionsData[row.item.type].data"
              :extraWidth="optionsData[row.item.type].maxLength"
              v-model="row.item.accounts"
              v-bind:class="{
                'is-valid': row.item.accounts,
                'is-invalid': !row.item.accounts
              }"
              @account-selected="accountVal => updateAccounts(accountVal, row)"
              @account-remove="() => removeAccounts(row.item)"
              :sortLocal="true"
              :enableReload="true"
              :key="row.index"
            >
            </table-custom-multiselect>
          </b-form-group>
        </template>
        <template v-else>{{
          row.item.accounts ? row.item.accounts.title : null
        }}</template>
      </template>

      <!-- ACCOUNT HEAD -->
      <template v-slot:head(account_action)>
        <b-button
          variant="primary"
          class="text-regular mr-2 action-button tableheader-btn float-right"
          @click="updateAllTrans"
          v-if="!disableOkayAll"
          >{{ $t(translationPath + "ok_all") }}</b-button
        >
      </template>
      <template v-slot:cell(account_action)="row">
        <b-link
          class="btn-sm btn-primary small-ok-button"
          v-if="row.item.suggestion"
          @click="disableSuggestionRow(row)"
          :disabled="!row.item.accounts"
          >{{ $t("common.ok") }}</b-link
        >
      </template>
      <template v-slot:cell(percentage)="row">
        <template
          v-if="enableEditingDetails.indexOf(row.item.index) > -1"
          class="percentageDiv"
        >
          <b-form-group
            class="mb-0 percentageformgroup float-left float-lg-right"
            :state="
              row.item.percentage
                ? parseInt(row.item.percentage) >= 0 &&
                  parseInt(row.item.percentage) <= 100
                : null
            "
          >
            <vue-numeric
              v-model="row.item.percentage"
              v-bind:class="{
                'form-control percentage_input': true,
                'is-valid':
                  row.item.percentage &&
                  parseInt(row.item.percentage) >= 0 &&
                  parseInt(row.item.percentage) <= 100,
                'is-invalid':
                  row.item.percentage &&
                  (parseInt(row.item.percentage) < 0 ||
                    parseInt(row.item.percentage) > 100)
              }"
              v-bind:min="0"
              v-bind:max="100"
              currency="%"
              currency-symbol-position="suffix"
            ></vue-numeric>
          </b-form-group>
        </template>
        <template v-else>{{ row.item.percentage | percentageFilter }}</template>
      </template>
      <template v-slot:cell(amount)="row">
        <template v-if="enableEditingDetails.indexOf(row.item.index) > -1">
          <b-form-group
            class="mb-0 float-left float-lg-right"
            :state="
              row.item.amount || parseFloat(row.item.amount) >= 0 ? true : false
            "
            :invalid-feedback="
              !row.item.amount && parseFloat(row.item.amount) != 0
                ? $t(translationPath + 'form.errors.required_error')
                : ''
            "
          >
            <currency-input
              :ref="'CurrencyInputAmount' + row.item.index"
              v-model.number="row.item.amount"
              :input-class="{
                'text-lg-right': true,
                'is-valid': row.item.amount || parseFloat(row.item.amount) >= 0,
                'is-invalid': !row.item.amount && parseFloat(row.item.amount) != 0
              }"
            />
          </b-form-group>
        </template>
        <template v-else>
          <span
            :class="
              'amount-' +
                (row.item.type !== 'transfer' &&
                row.item.type !== 'transfer_from' &&
                row.item.type !== 'transfer_to'
                  ? row.item.type
                  : 'transfer') +
                (row.item.type === 'transfer' ||
                row.item.type === 'transfer_from' ||
                row.item.type === 'transfer_to'
                  ? row.item.type === 'transfer_from'
                    ? ' transferSelf'
                    : ' transferOther'
                  : '')
            "
            >{{
              row.item.amount
                | formatAmount(
                  currentCOA.locale,
                  currentCOA.currency,
                  currentCOA.currency_iso,
                  false
                )
            }}</span
          >
        </template>
      </template>
      <template v-slot:cell(action)="row">
        <template v-if="enableEditingDetails.indexOf(row.item.index) > -1">
          <!--:disabled="!row.item.accounts"-->
          <div class="actionDiv">
            <b-button
              class="plain-btn action-button-row float-left saveButton"
              @click="saveAllData(row)"
              :disabled="
                !row.item.accounts ||
                  row.item.amount === null ||
                  !row.item.title ||
                  row.item.title.length < 2
              "
            >
              <i class="flaticon stroke checkmark text-success"></i>
              <span class="d-block d-lg-none text-success">{{
                $t("common.ok")
              }}</span>
            </b-button>
            <b-button
              class="plain-btn action-button-row float-left cancelButton"
              @click="cancelEdit(row)"
            >
              <i class="fa fa-times text-danger" alt="cancel"></i>
              <span class="d-block d-lg-none text-danger">{{
                $t("common.cancel")
              }}</span>
            </b-button>
          </div>
        </template>
        <template v-else>
          <div class="actionDiv text-xs-left text-sm-left text-md-left">
            <b-link
              href="#"
              @click="enableEdit(row)"
              :title="$t('common.edit')"
            >
              <i class="flaticon solid edit-3 text-gray"></i>
            </b-link>
            <!--<b-link href="#" @click="openSplitTransaction(row.item)" :title="$t('common.split_transaction')"><i class="flaticon solid share-3 text-gray"></i></b-link>-->
            <b-link
              href="#"
              @click="openSingleModal(row)"
              :title="$t('common.delete')"
            >
              <i class="flaticon solid trash-3 text-gray"></i>
            </b-link>
          </div>
        </template>
      </template>
    </b-table>
    <!--delete-modal-component-->
    <delete-modal
      ref="deleteImport"
      @delete-multiple="deleteCurrentTransaction"
      @delete-single="deleteSingleTransaction"
      @hide-single="cancelDelete"
    >
      <template v-slot:message v-if="selectedTransaction">
        <p>
          {{ $t(translationPath + "alert.confirm_delete") }}
          <b>{{ selectedTransaction.item.title }}</b>
        </p>
      </template>
    </delete-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MassEditImportForm from '@/components/common/MassEditImportForm'
import { en, da, sv } from 'vuejs-datepicker/dist/locale'
import axios from 'axios'
import TableCustomMultiselect from '@/components/common/TableCustomMultiselect'
import VueNumeric from 'vue-numeric'
import Loader from '@/components/common/Loader'
import Datepicker from 'vuejs-datepicker'
import CurrencyInput from '@/components/common/CurrencyInput'
import InformationTab from '../tabs/InformationTab'
import RawDataTab from '../tabs/RawDataTab'
import SplitTab from '../tabs/SplitTab'
import AdditionalTab from '../tabs/AdditionalTab'
import formatAmount from '@/assets/filters/formatAmount'
import _ from 'lodash'
import DeleteModal from '../modals/DeleteModal'
import { BIconX } from 'bootstrap-vue'

export default {
  name: 'ImportTransactionTable',
  props: {
    parsedTransactions: {
      type: Object,
      default: function () {
        return {}
      }
    },
    disableOkayAll: {
      type: Boolean,
      default: false
    },
    offsetAccountName: {
      type: String,
      default: null
    },
    detailRow: {
      type: Boolean,
      default: false
    },
    tab: {
      type: Object,
      default: function () {
        return {
          info: true,
          split: true,
          additional: true,
          raw: true
        }
      }
    }
  },
  components: {
    TableCustomMultiselect,
    VueNumeric,
    Loader,
    MassEditImportForm,
    Datepicker,
    CurrencyInput,
    InformationTab,
    RawDataTab,
    SplitTab,
    AdditionalTab,
    BIconX,
    DeleteModal
  },
  data () {
    return {
      translationPath: 'transactions.import.import_wizard_v2.step3.',
      startWeekByMonday:
        Object.prototype.hasOwnProperty.call(
          process.env,
          'VUE_APP_DATEPICKER_START_DAY_BY_MONDAY'
        ) && process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY
          ? process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY === 'true'
          : false,
      transaction_filter: null,
      allSelected: false,
      indeterminate: false,
      busyState: false,
      massUpdate: false,
      addNewTransaction: false,
      triggerValidations: false,
      selectedTransaction: null,
      disableSingleRows: [],
      enableEditingDetails: [],
      transactions: [],
      selected: [],
      hoveredRow: [],
      accountsGroups: [],
      restrictDeleteIndex: [],
      TransactionPerPage: 0,
      TransactionTotalRows: 0,
      TransactionCurrentPage: 1,
      accountOptions: {
        enable: true,
        required: true
      },
      percentageOptions: {
        enable: true
      },
      datepickerLang: {
        en: en,
        sv: sv,
        da: da
      },
      optionsData: {
        income: {
          data: [],
          loadingstatus: false,
          maxLength: 0
        },
        expense: {
          data: [],
          loadingstatus: false,
          maxLength: 0
        },
        transfer: {
          data: [],
          loadingstatus: false,
          maxLength: 0
        },
        transfer_to: {
          data: [],
          loadingstatus: false,
          maxLength: 0
        },
        transfer_from: {
          data: [],
          loadingstatus: false,
          maxLength: 0
        },
        refund: {
          data: [],
          loadingstatus: false,
          maxLength: 0
        }
      }
    }
  },
  created () {
    this.onKeyDelete = this.onKeyDelete.bind(this)
    window.addEventListener('keypress', this.onKeyDelete)
    window.addEventListener('keyup', this.keyUpHandler)
    this.fetchAccountsByTypeCondition('income').then(IncomeData => {
      this.fetchAccountsByTypeCondition('expense').then(ExpenseData => {
        this.fetchAccountsByTypeCondition('transfer').then(TransferData => {
          const AllIncomes = _.flattenDeep(IncomeData)
          const AllExpense = _.flattenDeep(ExpenseData)
          const AllTransfer = _.flattenDeep(TransferData)
          this.accountsGroups = [...AllIncomes, ...AllExpense, ...AllTransfer]
          this.loadParsedData()
        })
      })
    })
  },
  destroyed () {
    window.removeEventListener('keypress', this.onKeyDelete)
    window.removeEventListener('keyup', this.keyUpHandler)
  },
  computed: {
    ...mapState('user', ['currentCOA', 'currentUser']),
    // Datepicker locales
    selectedLang () {
      return this.datepickerLang[this.$i18n.locale]
    },
    // All transactions
    allTransactions () {
      return this.transactions
    },
    // check weather all transactions has title
    allTransactionsTitleExists () {
      const withoutTitle = this.transactions.filter(
        item => !item.title || item.title.toString().trim().length === 0
      )
      return withoutTitle.length
    },
    // Table field
    fieldValues () {
      let fields = []
      // detailRow
      if (this.detailRow) {
        fields.push({
          key: 'show_details',
          label: '',
          sortable: false,
          show: true,
          checkboxLabel: 'show_details'
        })
      }
      fields = [
        ...fields,
        {
          key: 'date',
          label: this.$t(this.translationPath + 'table.head.date'),
          sortable: true,
          class: 'td-col-date'
        },
        {
          key: 'title',
          label: this.$t(this.translationPath + 'table.head.title'),
          class: 'td-col-title'
        },
        {
          key: 'types',
          label: this.$t(this.translationPath + 'table.head.type'),
          class: 'td-col-types'
        },
        {
          key: 'account',
          label: this.currentUser.strict_accounting_mode
            ? this.$t(this.translationPath + 'table.head.account')
            : this.$t(this.translationPath + 'table.head.category'),
          class: 'td-col-account'
        }
      ]
      if (!this.disableOkayAll) {
        fields.push({
          key: 'account_action',
          label: '',
          sortable: false,
          class: 'td-col-ok-buttons'
        })
      }
      return [
        ...fields,
        {
          key: 'percentage',
          label: this.$t(this.translationPath + 'table.head.percentage'),
          class: 'text-left text-lg-right td-col-percentage'
        },
        {
          key: 'amount',
          label: this.$t(this.translationPath + 'table.head.amount'),
          class: 'text-left text-lg-right td-col-amount'
        },
        {
          key: 'action',
          label: '',
          class: 'text-center td-col-action'
        },
        {
          key: 'checkbox',
          label: this.$t('common.mulit_action_label'),
          sortable: false,
          class: 'td-col-checkbox'
        }
      ]
    },
    // transactions type options
    transactionTypeOptions () {
      return [
        {
          value: 'income',
          text: this.$t('common.transaction_types.deposit')
        },
        {
          value: 'expense',
          text: this.$t('common.transaction_types.withdrawal')
        },
        {
          value: 'refund',
          text: this.$t('common.transaction_types.refund')
        },
        {
          value: 'transfer_to',
          text: this.$t(
            this.translationPath + 'transaction_type_options.transfer_to',
            {
              offsetAccountName: this.offsetAccountName
            }
          )
        },
        {
          value: 'transfer_from',
          text: this.$t(
            this.translationPath + 'transaction_type_options.transfer_from',
            {
              offsetAccountName: this.offsetAccountName
            }
          )
        }
      ]
    },
    // check weather all trnsactions has account/category
    allCategorySelected () {
      const withoutAccount = this.transactions.filter(item => !item.accounts)
      return withoutAccount.length
    },
    // translated placeholder
    searchPlaceholder () {
      return this.currentUser.strict_accounting_mode
        ? this.$t(this.translationPath + 'form.labels.type_to_filter_account')
        : this.$t(this.translationPath + 'form.labels.type_to_filter_category')
    },
    // No record text
    noResultText () {
      return this.currentUser.strict_accounting_mode
        ? this.$t('common.no_acount_found')
        : this.$t('common.no_category_found')
    },
    // Category/account placeholder
    placeHolder () {
      return this.currentUser.strict_accounting_mode
        ? this.$t(this.translationPath + 'form.labels.select_account')
        : this.$t(this.translationPath + 'form.labels.select_category')
    },
    // disabled the buttons
    disabledButtons () {
      return this.selected.length === 0
    }
  },
  methods: {
    // disable the edit state of record has suggestions
    disableSuggestionRow (row) {
      this.disableAccountRow(row)
    },
    // key up handler
    keyUpHandler (e) {
      const editEnabled = this.enableEditingDetails && this.enableEditingDetails.length > 0
      if (e.key === 'Escape' && editEnabled) {
        const hoveredRowIndex = this.hoveredRow.length > 0 ? this.hoveredRow[0].realIndex : null
        if (hoveredRowIndex && this.enableEditingDetails.indexOf(hoveredRowIndex) > -1) {
          this.cancelEdit({
            item: this.hoveredRow[0],
            index: this.hoveredRow[0].realIndex
          })
        } else if (editEnabled) {
          const lastIndex = this.enableEditingDetails[this.enableEditingDetails.length - 1]
          const lastIndexRow = this.allTransactions.filter(item => parseInt(item.index) === parseInt(lastIndex))
          if (lastIndexRow.length > 0) {
            this.cancelEdit({
              item: lastIndexRow[0],
              index: lastIndexRow[0].realIndex
            })
          }
        }
      }
    },
    // Enter key handler
    onKeyDelete (e) {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        if (typeof this.$refs.deleteImport !== 'undefined' && !this.$refs.deleteImport.isHidden) {
          this.$refs.deleteImport.emitEventClick()
        } else {
          const editEnabled = this.enableEditingDetails && this.enableEditingDetails.length > 0
          if (editEnabled) {
            const hoveredRowIndex = this.hoveredRow.length > 0 ? this.hoveredRow[0].realIndex : null
            if (hoveredRowIndex && this.enableEditingDetails.indexOf(hoveredRowIndex) > -1) {
              if (this.hoveredRow[0].date && this.hoveredRow[0].title && this.hoveredRow[0].accounts && this.hoveredRow[0].type && this.hoveredRow[0].amount !== null) {
                this.saveAllData({
                  item: this.hoveredRow[0],
                  index: this.hoveredRow[0].realIndex
                })
              }
            } else if (editEnabled) {
              const lastIndex = this.enableEditingDetails[this.enableEditingDetails.length - 1]
              const lastIndexRow = this.allTransactions.filter(item => parseInt(item.index) === parseInt(lastIndex)).filter(item => item.date && item.title && item.accounts && item.type && item.amount !== null)
              if (lastIndexRow.length > 0) {
                this.saveAllData({
                  item: lastIndexRow[0],
                  index: lastIndexRow[0].realIndex
                })
              }
            }
          }
        }
      }
    },
    async fetchAccountsByTypeCondition (type) {
      return this.fetchAccountRequestHandler(type)
        .then((optionArray) => {
          return optionArray.map(items => items.accounts)
        })
    },
    // handle transaction update
    handleUpdateTransactions (values) {
      this.submitted = true
      const submittedObj = Object.assign({}, values)
      if ((this.selected).length > 0) {
        _.forEach(this.selected, function (item, key) {
          if (Object.keys(submittedObj).indexOf('date_operator') > -1) {
            switch (submittedObj.date_operator) {
              case 'set_date':
                item.date = this.$moment(submittedObj.date_value).format('YYYY-MM-DD').toString()
                break
              case 'add_days':
                item.date = this.$moment(item.date).add(submittedObj.date_value, 'days').format('YYYY-MM-DD').toString()
                break
              case 'add_months':
                item.date = this.$moment(item.date).add(submittedObj.date_value, 'months').format('YYYY-MM-DD').toString()
                break
              case 'add_year':
                item.date = this.$moment(item.date).add(submittedObj.date_value, 'years').format('YYYY-MM-DD').toString()
                break
              case 'subtract_days':
                item.date = this.$moment(item.date).subtract(submittedObj.date_value, 'days').format('YYYY-MM-DD').toString()
                break
              case 'subtract_months':
                item.date = this.$moment(item.date).subtract(submittedObj.date_value, 'days').format('YYYY-MM-DD').toString()
                break
              case 'subtract_years':
                item.date = this.$moment(item.date).subtract(submittedObj.date_value, 'years').format('YYYY-MM-DD').toString()
                break
            }
          }
          if (Object.keys(submittedObj).indexOf('amount_operator') > -1) {
            if (item.type === 'income' && submittedObj.amount_value < 0 && submittedObj.amount_operator !== 'subtract_by') {
              item.type = 'expense'
              submittedObj.amount_value = Math.abs(submittedObj.amount_value)
            } else if (item.type === 'expense' && submittedObj.amount_value < 0 && submittedObj.amount_operator !== 'subtract_by') {
              item.type = 'income'
              submittedObj.amount_value = Math.abs(submittedObj.amount_value)
            } else if (item.type === 'transfer_to' && submittedObj.amount_value < 0 && submittedObj.amount_operator !== 'subtract_by') {
              item.type = 'transfer_from'
              submittedObj.amount_value = Math.abs(submittedObj.amount_value)
            } else if (item.type === 'transfer_from' && submittedObj.amount_value < 0 && submittedObj.amount_operator !== 'subtract_by') {
              item.type = 'transfer_to'
              submittedObj.amount_value = Math.abs(submittedObj.amount_value)
            }
            switch (submittedObj.amount_operator) {
              case 'set_amount': {
                item.amount = parseFloat(submittedObj.amount_value) >= 0 ? parseFloat(submittedObj.amount_value) : 0
                break
              }
              case 'multiply_with': {
                item.amount = parseFloat(item.amount) * parseFloat(submittedObj.amount_value)
                break
              }
              case 'divide_by': {
                item.amount = parseFloat(item.amount) / parseFloat(submittedObj.amount_value)
                break
              }
              case 'subtract_by': {
                const updatedAmount = parseFloat(item.amount) - parseFloat(submittedObj.amount_value)
                item.amount = updatedAmount < 0 ? 0 : updatedAmount
                break
              }
              case 'add_to': {
                item.amount = parseFloat(item.amount) + parseFloat(submittedObj.amount_value)
                break
              }
            }
          }
          if (submittedObj.title_operator && submittedObj.title_value) {
            switch (submittedObj.title_operator) {
              case 'remove_part':
                item.title = (item.title).replace(submittedObj.title_value, '')
                break
              case 'append_part':
                item.title = (item.title + ' ' + submittedObj.title_value).trim()
                break
              case 'set_title':
                item.title = submittedObj.title_value ? submittedObj.title_value : item.title
                break
              case 'prefix_part':
                item.title = (submittedObj.title_value + ' ' + item.title).trim()
                break
            }
          } else if (submittedObj.title_operator && !submittedObj.title_value) {
            switch (submittedObj.title_operator) {
              case 'lowercase':
                item.title = (item.title).toLowerCase()
                break
              case 'upper_first':
                item.title = _.upperFirst((item.title).toLowerCase())
                break
              case 'capitalize':
                item.title = (item.title).toUpperCase()
                break
            }
          }
          if (Object.keys(submittedObj).indexOf('accounts') > -1) {
            item.accounts = submittedObj.accounts
            item.percentage = submittedObj.accounts.default_active_percentage
          }
          if (Object.keys(submittedObj).indexOf('percentage') > -1) {
            item.percentage = submittedObj.percentage
          }
          if (Object.keys(submittedObj).indexOf('transaction_type') > -1) {
            item.type = submittedObj.transaction_type
          }
          if (Object.keys(submittedObj).indexOf('duplicate') > -1) {
            item.duplicate = submittedObj.duplicate
          }
          if (Object.keys(submittedObj).indexOf('is_duplicate') > -1) {
            item.is_duplicate = submittedObj.is_duplicate
          }
          if (item.accounts && item.suggestion === true) {
            item.suggestion = false
          }
        }.bind(this))
        const parseTransactions = this.transactions.filter(item => item.raw_string)
        const addTransactions = this.transactions.filter(item => item.time)
        this.massUpdate = true
        this.$emit('update-transactions', { transactions: parseTransactions, user_transactions: addTransactions })
        this.$refs.transactionTableView.refresh()
        this.selected = []
        this.$bvToast.toast(this.$t(this.translationPath + 'toast.update_mulitple_transactions'), {
          variant: 'success',
          'auto-hide-delay': 5000,
          title: this.$t(this.translationPath + 'toast.title')
        })
      }
    },
    // handle update on information tab
    handleUpdateTabTransaction (item, row) {
      this.massUpdate = true
      this.transactions = this.transactions.map(el => {
        if (item.index === el.index && item.realIndex === el.realIndex) {
          return item
        } else {
          return el
        }
      })
      this.disableDetailsEditing({
        item: {
          index: item.index
        }
      })
      const indexRawString = this.disableSingleRows.indexOf(item.index)
      if (indexRawString === -1) {
        this.disableSingleRows.push(item.index)
      }
      row.toggleDetails()
    },
    // delete single row
    deleteSingleTransaction () {
      if (this.selectedTransaction) {
        this.deleteSingleRow(this.selectedTransaction)
      }
      this.$refs.deleteImport.hide()
    },
    deleteSingleRow (rowItem) {
      const excludeRow = rowItem.item
      this.massUpdate = true
      const transactions = this.transactions
        .filter(item => item.raw_string)
        .filter(item => {
          if (excludeRow.raw_string) {
            if (parseInt(excludeRow.index) !== parseInt(item.index)) {
              return item
            }
          } else {
            return item
          }
        })
      const newTransacton = this.transactions
        .filter(item => !item.raw_string)
        .filter(item => {
          if (excludeRow.time) {
            if (parseInt(excludeRow.index) !== parseInt(item.index)) {
              return item
            }
          } else {
            return item
          }
        })
      this.$emit('delete-transactions', {
        transactions: transactions,
        user_transactions: newTransacton
      })
      this.successDeleteToast()
    },
    // delete toast
    successDeleteToast () {
      this.$bvToast.toast(
        this.$t(this.translationPath + 'toast.transaction_deleted'),
        {
          title: this.$t(this.translationPath + 'toast.title'),
          variant: 'success',
          solid: true
        }
      )
    },
    // Open delete model
    openSingleModal (row) {
      this.selectedTransaction = row
      this.$refs.deleteImport.show()
    },
    // cancel delete
    cancelDelete (row) {
      this.selectedTransaction = null
    },
    getAllTransactionalData (filterIndex) {
      const ParseTransaction =
        this.parsedTransactions && this.parsedTransactions
          ? this.parsedTransactions.transactions
          : []
      const AddedTrasactions =
        this.parsedTransactions && this.parsedTransactions.user_transactions
          ? this.parsedTransactions.user_transactions
          : []
      const MergeTransactions = [...ParseTransaction, ...AddedTrasactions]
      MergeTransactions.sort(function (a, b) {
        const item1Date = new Date(a.date)
        const item2Date = new Date(b.date)
        if (item1Date.getTime() < item2Date.getTime()) {
          return 1
        }
        if (item1Date.getTime() > item2Date.getTime()) {
          return -1
        }
        return 0
      })
      const mapTransactions = MergeTransactions.map((items, index) => {
        return Object.assign({}, items, {
          index: index,
          suggestion: items.suggestion || false
        })
      }).filter(item => item.index === filterIndex)
      return mapTransactions.length > 0 ? mapTransactions[0] : null
    },
    updateAccounts (value, row) {
      const rowitem = row.item
      const transactionsUpdated = this.transactions.filter(item => {
        if (item.raw_string) {
          return Object.assign(
            {},
            {
              amount: item.amount,
              balance: item.balance,
              date: item.date,
              raw_string: item.raw_string,
              title: item.title,
              transaction_category: item.transaction_category,
              transaction_type: item.transaction_type,
              type: item.type,
              percentage: item.percentage || 0,
              accounts: item.accounts || null
            }
          )
        } else {
          return Object.assign(
            {},
            {
              amount: item.amount,
              date: item.date,
              time: item.time,
              title: item.title,
              type: item.type || null,
              percentage: item.percentage || 0,
              accounts: item.accounts || null
            }
          )
        }
      })
      const filterParseTrans = transactionsUpdated.filter(item => {
        if (Object.prototype.hasOwnProperty.call(item, 'raw_string')) {
          return item
        }
      })
      const filterAddedTrans = transactionsUpdated.filter(item => {
        if (Object.prototype.hasOwnProperty.call(item, 'time')) {
          return item
        }
      })
      if (Object.keys(rowitem).indexOf('raw_string') > -1) {
        const updateTrans = filterParseTrans.findIndex(function (element) {
          return (
            element.raw_string.replace(' ', '') ===
              rowitem.raw_string.replace(' ', '') &&
            element.index === rowitem.index
          )
        })
        rowitem.accounts = value.accounts ? value.accounts : null
        rowitem.percentage = value.accounts
          ? value.accounts.default_active_percentage || 0
          : 0
        rowitem.suggestion = false
        filterParseTrans.splice(updateTrans, 1, rowitem)
      } else {
        const updateTrans = filterAddedTrans.findIndex(function (element) {
          return (
            element.time === rowitem.time && element.index === rowitem.index
          )
        })
        rowitem.accounts = value.accounts ? value.accounts : null
        rowitem.percentage = value.accounts
          ? value.accounts.default_active_percentage || 0
          : 0
        rowitem.suggestion = false
        filterAddedTrans.splice(updateTrans, 1, rowitem)
      }
      const newTransactions = [...filterParseTrans, ...filterAddedTrans]
      newTransactions.sort(function (a, b) {
        const item1Date = new Date(a.date)
        const item2Date = new Date(b.date)
        if (item1Date.getTime() < item2Date.getTime()) {
          return 1
        }
        if (item1Date.getTime() > item2Date.getTime()) {
          return -1
        }
        return 0
      })
      this.transactions = newTransactions
      if (this.enableEditingDetails.indexOf(rowitem.index) === -1) {
        this.clearClickedRowSelection(rowitem.index)
      }
      if (rowitem.title && rowitem.title.toString().trim().length > 0) {
        if (this.enableEditingDetails.indexOf(rowitem.index) === -1) {
          this.disableSingleRows.push(rowitem.index)
          this.disableSingleRows = this.disableSingleRows.sort()
        }
      }
      this.massUpdate = true
    },
    deleteCurrentTransaction () {
      const getFilteredTrans = this.selected
        .filter(item => item.raw_string)
        .map(item => parseInt(item.index))
      const getFilteredAddTrans = this.selected.filter(
        item => getFilteredTrans.indexOf(parseInt(item.index)) === -1
      )
      const filteredTrans = this.transactions
        .filter(item => item.raw_string)
        .filter(item => getFilteredTrans.indexOf(parseInt(item.index)) === -1)
      const filteredAddTrans = this.transactions
        .filter(item => !item.raw_string)
        .filter(
          item => getFilteredAddTrans.indexOf(parseInt(item.index)) === -1
        )
      this.selected = []
      this.massUpdate = true
      this.$emit('delete-transactions', {
        transactions: filteredTrans,
        user_transactions: filteredAddTrans
      })
      this.resetSelectedRows()
      this.$refs.deleteImport.hide()
      this.successDeleteToast()
    },
    selectCheckBoxRow (value, index) {
      if (value) {
        this.$refs.transactionTableView.selectRow(index)
      } else {
        this.$refs.transactionTableView.unselectRow(index)
      }
    },
    removeAndAddTrans (value) {
      const selectedTrans =
        typeof this.transactions[this.splitId] !== 'undefined'
          ? this.transactions[this.splitId]
          : null
      let transactions = this.transactions.filter(item => item.raw_string)
      let userTransactions = this.transactions.filter(item => !item.raw_string)
      if (selectedTrans !== null) {
        if (
          Object.prototype.hasOwnProperty.call(selectedTrans, 'raw_string') &&
          selectedTrans.raw_string
        ) {
          transactions = transactions.filter(
            item => item.index !== selectedTrans.index
          )
        } else {
          userTransactions = userTransactions.filter(
            item => item.index !== selectedTrans.index
          )
        }
      }
      const addedTrans = value.map(item => {
        const currentDate = new Date()
        const { title, type, amount, account, date } = item
        return {
          is_new: true,
          title,
          time: currentDate.getTime(),
          type,
          percentage: account.default_active_percentage,
          amount,
          accounts: account,
          date
        }
      })
      this.massUpdate = true
      this.$emit('delete-transactions', {
        transactions: transactions,
        user_transactions: userTransactions
      })
      this.massUpdate = true
      this.$emit('add-transaction', {
        user_transactions: [...userTransactions, ...addedTrans]
      })
      this.$refs.splitTansactionModal.close()
    },
    dateFormatter (date) {
      const local = this.currentCOA.locale
      return this.$moment(date)
        .locale(local)
        .format('YYYY-MM-DD')
    },
    updateTransactionRow (row, all) {
      const allStatus = all || false
      const transactionsUpdated = this.transactions.map(item => {
        if (item.raw_string) {
          if (
            row.raw_string &&
            item.raw_string === row.raw_string &&
            item.index === row.index
          ) {
            const amountChanged =
              this.parsedTransactions.transactions[item.realIndex].amount !==
              row.amount
            const titleChanged =
              this.parsedTransactions.transactions[item.realIndex].title !==
              row.title
            const dateChanged =
              this.parsedTransactions.transactions[item.realIndex].date !==
              row.date
            const updateDuplicate =
              amountChanged === true ||
              titleChanged === true ||
              dateChanged === true
            const duplicateValue =
              updateDuplicate === true ? 0 : item.duplicate
            const duplicate =
              item.duplicate === 1 ? duplicateValue : item.duplicate
            const isDuplicate = duplicate === 1
            return Object.assign({}, item, {
              amount: item.amount,
              balance: item.balance,
              date: this.$moment(item.date).format('YYYY-MM-DD'),
              raw_string: item.raw_string,
              title: item.title,
              transaction_category: item.transaction_category,
              transaction_type: item.transaction_type,
              type: row.type,
              percentage: row.percentage || 0,
              accounts: row.accounts || null,
              suggestion:
                item.suggestion && row.accounts ? false : item.suggestion,
              duplicate: duplicate,
              is_duplicate: isDuplicate
            })
          } else {
            if (
              row.title === item.title &&
              row.suggestion === true &&
              item.suggestion === false &&
              !item.account &&
              item.index === row.index
            ) {
              return Object.assign({}, item, {
                amount: item.amount,
                balance: item.balance,
                date: this.$moment(item.date).format('YYYY-MM-DD'),
                raw_string: item.raw_string,
                title: item.title,
                transaction_category: item.transaction_category,
                transaction_type: item.transaction_type,
                type: row.type,
                percentage: row.percentage || 0,
                accounts: row.accounts || null,
                suggestion:
                  allStatus === true
                    ? item.suggestion && row.accounts
                      ? false
                      : item.suggestion
                    : item.suggestion,
                duplicate: item.duplicate,
                is_duplicate: item.is_duplicate
              })
            }
            return Object.assign({}, item, {
              amount: item.amount,
              balance: item.balance,
              date: this.$moment(item.date).format('YYYY-MM-DD'),
              raw_string: item.raw_string,
              title: item.title,
              transaction_category: item.transaction_category,
              transaction_type: item.transaction_type,
              type: item.type,
              percentage: item.percentage || 0,
              accounts: item.accounts || null,
              suggestion:
                allStatus === true
                  ? item.suggestion && row.accounts
                    ? false
                    : item.suggestion
                  : item.suggestion,
              duplicate: item.duplicate,
              is_duplicate: item.is_duplicate
            })
          }
        } else {
          if (row.time && item.time === row.time && item.index === row.index) {
            return Object.assign({}, item, {
              amount: item.amount,
              date: this.$moment(item.date).format('YYYY-MM-DD'),
              time: item.time,
              title: item.title,
              type: row.type || null,
              percentage: row.percentage || 0,
              accounts: row.accounts || null
            })
          } else {
            if (
              row.title === item.title &&
              row.suggestion === true &&
              item.suggestion === false &&
              !item.account &&
              item.index === row.index
            ) {
              return Object.assign({}, item, {
                amount: item.amount,
                date: this.$moment(item.date).format('YYYY-MM-DD'),
                time: item.time,
                title: item.title,
                type: row.type || null,
                percentage: row.percentage || 0,
                accounts: row.accounts || null
              })
            }
            return Object.assign({}, item, {
              amount: item.amount,
              date: this.$moment(item.date).format('YYYY-MM-DD'),
              time: item.time,
              title: item.title,
              type: item.type || null,
              percentage: item.percentage || 0,
              accounts: item.accounts || null
            })
          }
        }
      })
      const filteredTrans = transactionsUpdated.filter(item => item.raw_string)
      const filteredAdd = transactionsUpdated.filter(item => !item.raw_string)
      this.$emit('update-transactions', {
        transactions: filteredTrans,
        user_transactions: filteredAdd
      })
    },
    updateAllTrans () {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line
        this.transactions
          .filter(item => item.accounts && item.title)
          .map(items => {
            this.updateTransactionRow(items, true)
            return items
          })
          .map(items => items.index)
          .forEach(items => {
            if (this.disableSingleRows.indexOf(items) === -1) {
              this.disableSingleRows.push(items)
            }
            if (this.enableEditingDetails.indexOf(items) > -1) {
              this.enableEditingDetails.splice(
                this.enableEditingDetails.indexOf(items),
                1
              )
            }
          })
        resolve(true)
      })
    },
    disableAccountRow (row) {
      this.disableEditStatus(row, 'account')
    },
    saveAllData (row) {
      this.disableEditStatus(row, 'details')
      this.disableAccountRow(row)
    },
    toggleAll (checked) {
      const transactionsItems = this.transactions
      if (checked) {
        transactionsItems.forEach(elem => {
          const selectedIndex = [...this.selected].map(
            valueItem => valueItem.index
          )
          if (
            selectedIndex.indexOf(elem.index) === -1 &&
            this.restrictDeleteIndex.indexOf(elem.index) === -1
          ) {
            this.$nextTick(function () {
              this.selected.push(elem)
            })
          }
        })
      } else {
        this.selected = []
      }
    },
    resetSelectedRows () {
      this.toggleAll(false)
      this.$refs.transactionTableView.clearSelected()
    },
    validate () {
      this.triggerValidations = true
      return this.updateAllTrans().then(async data => {
        if (this.allCategorySelected === 0 && this.allTransactions.length > 0) {
          if (this.allTransactionsTitleExists === 0) {
            this.$emit('validate-success', this.finalModel)
            return true
          } else {
            this.$bvToast.toast(
              this.$t(this.translationPath + 'toast.message_title', {
                count: this.allTransactionsTitleExists
              }),
              {
                title: this.$t(this.translationPath + 'toast.title'),
                variant: 'danger',
                solid: true
              }
            )
            return false
          }
        } else {
          // let InfoText = this.currentUser.strict_accounting_mode ? 'account' : 'category'
          if (this.allTransactions.length === 0) {
            this.$bvToast.toast(
              this.$t(this.translationPath + 'toast.no_transactions'),
              {
                title: this.$t(this.translationPath + 'toast.title'),
                variant: 'danger',
                solid: true
              }
            )
          }
          return false
        }
      })
    },
    reset () {
      this.disableSingleRows = []
      this.enableEditingDetails = []
      this.triggerValidations = false
      this.busyState = false
    },
    rowHoveredHandler (item) {
      const selectedItem = []
      selectedItem.push(item)
      this.hoveredRow = selectedItem
    },
    rowUnHoveredHandler (item) {
      this.hoveredRow = []
    },
    onFilteredTransactionAccounts (filteredItems) {
      this.TransactionTotalRows = filteredItems.length
      this.TransactionCurrentPage = 1
      const filteredIndex = filteredItems.map(item => item.index)
      this.restrictDeleteIndex = this.transactions
        .map(item => item.index)
        .filter(item => filteredIndex.indexOf(item) === -1)
    },
    fetchAccountRequestHandler (transactionType) {
      const AccountType = this.transactionTypeIdentify(transactionType, true)
      return axios
        .get(
          `${process.env.VUE_APP_ROOT_API}/accounts/groups/for/multiselect?account_type=${AccountType}&log=ImportTransactionTable`
        )
        .then(response => {
          const optionArray = []
          const accountGroups = response.data.data
          Object.keys(accountGroups).forEach(
            function (key) {
              if (AccountType === 'asset,liability') {
                optionArray.push({
                  title:
                    (this.$te('common.' + accountGroups[key].type.toLowerCase())
                      ? this.$t(
                        'common.' + accountGroups[key].type.toLowerCase()
                      )
                      : accountGroups[key].type) +
                    ' / ' +
                    accountGroups[key].title,
                  type: accountGroups[key].type,
                  accounts: accountGroups[key].accounts
                })
              } else {
                optionArray.push({
                  title: accountGroups[key].title,
                  type: accountGroups[key].type,
                  accounts: accountGroups[key].accounts
                })
              }
            }.bind(this)
          )
          return optionArray
        })
        .catch(error => {
          console.error(error)
          return []
        })
    },
    fetchAccountsByType (type) {
      const AccountType = this.transactionTypeIdentify(type, true)
      if (!this.optionsData[type].loadingstatus) {
        this.optionsData[type].loadingstatus = true
        this.fetchAccountRequestHandler(type).then(optionArray => {
          this.optionsData[type].data =
            AccountType === 'asset,liability'
              ? optionArray.sort(function (a, b) {
                const nameA = a.type
                const nameB = b.type
                if (nameA < nameB) {
                  return -1
                }
                if (nameA > nameB) {
                  return 1
                }
                return 0
              })
              : optionArray
          if (
            this.optionsData[type].data &&
            this.optionsData[type].data.length > 0
          ) {
            const allAccounts = this.optionsData[type].data
              .map(item => item.accounts)
              .reduce((val, acc) => Object.assign([], [...val, ...acc]))
              .map(itemAcc => itemAcc.title)
              .map(itemAcc => itemAcc.length)
            const sortAllAccountLength =
              allAccounts.length > 0
                ? allAccounts.sort((a, b) => {
                  if (a > b) {
                    return -1
                  }
                  if (a < b) {
                    return 1
                  }
                  return 0
                })
                : allAccounts
            const maximumCharacters =
              sortAllAccountLength.length > 0 ? sortAllAccountLength[0] : 0
            this.optionsData[type].maxLength =
              maximumCharacters >= 30 ? maximumCharacters : 0
          }
        })
      }
    },
    rowClass (item) {
      if (!item) return
      const suggestionClass = !item.suggestion ? 'no_suggestion' : null
      if (this.triggerValidations === false) return suggestionClass
      if (
        Object.prototype.hasOwnProperty.call(item, 'accounts') &&
        !item.accounts
      ) {
        return suggestionClass
          ? `invalid-row ${suggestionClass}`
          : 'invalid-row'
      } else if (
        Object.prototype.hasOwnProperty.call(item, 'accounts') &&
        item.accounts
      ) {
        if (
          !item.date ||
          !item.title ||
          (!item.amount && parseFloat(item.amount) !== 0) ||
          !item.type
        ) {
          return suggestionClass
            ? `invalid-row ${suggestionClass}`
            : 'invalid-row'
        }
        if (item.is_duplicate) {
          return suggestionClass
            ? `duplicate-row ${suggestionClass}`
            : 'duplicate-row'
        }
        return suggestionClass ? `valid-row ${suggestionClass}` : 'valid-row'
      }
    },
    checkIndexedRow (checkString, raw) {
      return raw === true
        ? this.selected.findIndex(
          value => value.raw_string === checkString.raw_string
        )
        : this.selected.findIndex(
          value =>
            value.title === checkString.title &&
              value.amount === checkString.amount &&
              value.date === checkString.date &&
              value.type === checkString.type &&
              value.time === checkString.time
        )
    },
    clearClickedRowSelection (index) {
      if (this.$refs.transactionTableView.isRowSelected(index)) {
        this.$refs.transactionTableView.unselectRow(index)
      }
    },
    enableEditStatus (rows, type) {
      const actionType = type || 'details'
      this.clearClickedRowSelection(rows.index)
      if (actionType === 'details') {
        this.enableEditingDetails.push(rows.item.index)
        return true
      }
      const indexRawString = this.disableSingleRows.indexOf(rows.item.index)
      if (indexRawString > -1) {
        this.disableSingleRows.splice(indexRawString, 1)
      }
    },
    enableEdit (dataRow) {
      const checkIndex = dataRow.item.raw_string
        ? this.checkIndexedRow(dataRow.item, true)
        : this.checkIndexedRow(dataRow.item, false)
      if (checkIndex > -1) {
        this.selected.splice(checkIndex, 1)
      }
      const details =
        Object.prototype.hasOwnProperty.call(dataRow, 'item') && dataRow.item
          ? dataRow.item
          : {}
      if (
        Object.prototype.hasOwnProperty.call(details, 'accounts') &&
        details.accounts &&
        Object.keys(details.accounts).length > 0 &&
        this.disableSingleRows.indexOf(dataRow.item.index) > -1
      ) {
        this.enableEditStatus(dataRow, 'account')
      }
      if (dataRow.item.suggestion) {
        dataRow.item.suggestion = false
      }
      this.enableEditStatus(dataRow)
    },
    disableEditStatus (rows, type) {
      this.clearClickedRowSelection(rows.index)
      const ObjectRow =
        typeof rows === 'object' &&
        Object.prototype.hasOwnProperty.call(rows, 'item') &&
        rows.item
          ? rows.item
          : rows
      if (type === 'details') {
        this.disableDetailsEditing(rows)
        return true
      }
      this.disableSingleRows.push(rows.item.index)
      this.disableSingleRows = this.disableSingleRows.sort()
      this.updateTransactionRow(ObjectRow)
      this.massUpdate = true
    },
    disableEditStatusCancel (rows) {
      this.clearClickedRowSelection(rows.index)
      // this.disableDetailsEditing(rows)
      this.disableEditStatus(rows, 'details')
      if (rows.item.accounts) {
        this.disableEditStatus(rows)
      }
      return true
    },
    disableDetailsEditing (rows) {
      const enableEditIndex = this.enableEditingDetails.indexOf(
        rows.item.index
      )
      if (enableEditIndex > -1) {
        this.enableEditingDetails.splice(enableEditIndex, 1)
      }
    },
    cancelEdit (row) {
      const cancelIndex = row.item.index
      const refreshedData = this.getAllTransactionalData(cancelIndex)
      const updateTransactions = [...this.transactions].map(item => {
        if (item.index === cancelIndex) {
          const cancelItem = Object.prototype.hasOwnProperty.call(
            item,
            'raw_string'
          )
            ? {
              amount: Number(refreshedData.amount),
              title: refreshedData.title,
              date: refreshedData.date,
              duplicate: refreshedData.duplicate,
              is_duplicate: refreshedData.duplicate === 1
            }
            : {
              amount: Number(refreshedData.amount),
              title: refreshedData.title,
              date: refreshedData.date
            }
          return Object.assign(item, cancelItem)
        } else {
          return item
        }
      })
      this.transactions = updateTransactions
      this.disableEditStatusCancel(row)
    },
    transactionTypeIdentify (type, identifyType = null) {
      const identifyTransactionType =
        identifyType && type === 'refund' ? 'expense' : type
      // transaction type
      const validTransactionTypes = identifyType
        ? ['income', 'expense', 'asset,liability']
        : ['income', 'expense', 'transfer_to', 'transfer_from', 'refund']
      if (validTransactionTypes.indexOf(identifyTransactionType) === -1) {
        return validTransactionTypes[validTransactionTypes.length - 1]
      }
      return type
    },
    loadParsedData () {
      const ParseTransaction =
        this.parsedTransactions &&
        Object.prototype.hasOwnProperty.call(
          this.parsedTransactions,
          'transactions'
        ) &&
        this.parsedTransactions.transactions
          ? this.parsedTransactions.transactions
          : []
      const AddedTrasactions =
        this.parsedTransactions &&
        Object.prototype.hasOwnProperty.call(
          this.parsedTransactions,
          'user_transactions'
        ) &&
        this.parsedTransactions.user_transactions
          ? this.parsedTransactions.user_transactions
          : []
      const filterParse = ParseTransaction.map((item, index) => {
        const TransactionType = this.transactionTypeIdentify(item.type)
        const transactionObject = Object.assign({}, item, {
          type: TransactionType,
          suggestion: item.suggestion || false,
          realIndex: index
        })
        // get accounts options
        if (item.suggestion_account_id > -1 && !item.accounts) {
          const FilterSuggestGroup = this.accountsGroups.filter(
            accItem => accItem.id === item.suggestion_account_id
          )
          const selectedAccount =
            FilterSuggestGroup.length > 0 ? FilterSuggestGroup[0] : null
          Object.assign(transactionObject, {
            accounts: selectedAccount,
            percentage:
              selectedAccount &&
              Object.prototype.hasOwnProperty.call(
                selectedAccount,
                'default_active_percentage'
              ) &&
              selectedAccount.default_active_percentage
                ? selectedAccount.default_active_percentage
                : 0,
            type:
              selectedAccount &&
              Object.prototype.hasOwnProperty.call(selectedAccount, 'type') &&
              selectedAccount.type &&
              ['income', 'expense'].indexOf(selectedAccount.type) > -1
                ? selectedAccount.type
                : item.type,
            suggestion: selectedAccount !== null
          })
        }
        if (!Object.prototype.hasOwnProperty.call(item, 'beforeUpdateIndex')) {
          Object.assign(transactionObject, {
            beforeUpdateIndex: null
          })
        }
        return transactionObject
      })
      const filterAdded = AddedTrasactions.map((item, index) => {
        const TransactionType = this.transactionTypeIdentify(item.type)
        return Object.assign(
          {},
          {
            amount: Number(item.amount),
            date: item.date,
            time: item.time,
            title: item.title,
            type: TransactionType || null,
            percentage: item.percentage || 0,
            accounts: item.accounts || null,
            duplicate: 0,
            realIndex: index,
            is_new: item.is_new || false,
            beforeUpdateIndex: item.beforeUpdateIndex || null
          }
        )
      })
      const MergeTransactions = [...filterParse, ...filterAdded]
      const FilteredTypes = MergeTransactions.filter(item => item.type).map(
        item => {
          return item.type
        }
      )
      const uniqueTypes = _.uniq(FilteredTypes)
      _.forEach(
        uniqueTypes,
        function (value) {
          this.fetchAccountsByType(value)
        }.bind(this)
      )
      MergeTransactions.sort(function (a, b) {
        const item1Date = new Date(a.date)
        const item2Date = new Date(b.date)
        if (item1Date.getTime() < item2Date.getTime()) {
          return 1
        }
        if (item1Date.getTime() > item2Date.getTime()) {
          return -1
        }
        return 0
      })
      let mapTransactions = MergeTransactions.map((items, index) => {
        return Object.assign({}, items, {
          index: index,
          suggestion: items.suggestion || false,
          beforeUpdateIndex:
            items.beforeUpdateIndex == null ? index : items.beforeUpdateIndex
        })
      })
      if (this.massUpdate) {
        if (this.enableEditingDetails.length > 0) {
          const filterIndex = mapTransactions
            .filter(
              item =>
                this.enableEditingDetails.indexOf(item.beforeUpdateIndex) > -1
            )
            .map(item => item.index)
          this.enableEditingDetails = filterIndex
          const disableSingleRows = mapTransactions
            .filter(item => item.accounts)
            .filter(item => item.suggestion === false)
            .filter(item => filterIndex.indexOf(item.index) === -1)
            .map(item => item.index)
          this.disableSingleRows = disableSingleRows
        } else {
          const disableSingleRows = mapTransactions
            .filter(item => item.accounts)
            .filter(item => item.suggestion === false)
            .map(item => item.index)
          this.disableSingleRows = disableSingleRows
        }
        this.massUpdate = false
        mapTransactions = mapTransactions.map(item => {
          return Object.assign(item, {
            beforeUpdateIndex: item.index
          })
        })
      }
      if (this.addNewTransaction) {
        mapTransactions = mapTransactions.map(item => {
          return Object.assign(item, {
            beforeUpdateIndex: item.index
          })
        })
      }
      this.transactions = mapTransactions
      this.TransactiontotalRows = this.transactions.length
      this.TransactionPerPage = this.transactions.length
      this.busyState = false
    }
  },
  watch: {
    parsedTransactions: {
      handler: function () {
        if (this.parsedTransactions) {
          this.loadParsedData()
        }
      },
      immediate: true,
      deep: true
    },
    disabledButtons: {
      handler: function (value) {
        this.$emit('disable-button', value)
      }
    }
  },
  filters: {
    formatAmount,
    percentageFilter (value) {
      return value + '%'
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/modal.scss";

.spirecta-importable-table {
  .tab-close {
    position: absolute;
    right: 0;
    z-index: 1;
    font-size: 19px;
    color: #000;
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
  .custom-control {
    vertical-align: bottom;
  }
  .details-card {
    border: none;
    background: transparent;
    .tab-content {
      background: #fff;
      border-left: 1px solid rgba(0, 0, 0, 0.125);
      border-right: 1px solid rgba(0, 0, 0, 0.125);
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      border-top: 1px solid white;
    }
    .nav-tabs {
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      @media screen and (min-width: 992px) {
        height: 40px;
      }

      &.active {
        border-bottom: 2px solid #f7f7f7;
      }
      .nav-link {
        color: #ca807c;
        &.active {
          border-bottom: 2px solid white;
          background: white;
          font-weight: 800;
          color: #000;
        }

        &:hover {
          background: white;
          border-bottom: 2px solid white;
        }
      }
    }
  }
  .tableheader-btn {
    color: #fff;
    font-size: 14px;
    padding: 1px 3px;
    font-weight: 500;
    border-radius: 6px;
    min-width: 40px;
  }

  .displayHidden {
    display: none !important;
  }

  td a.badge-sidebar-link {
    color: black;
  }

  @media screen and (min-width: 992px) {
    .td-col-checkbox {
      padding-right: 0;
      width: 20px;
    }

    .td-col-date {
      white-space: nowrap;
      width: 100px;
    }

    .td-col-ok-buttons {
      width: 65px;
    }

    .td-col-types {
      width: 150px;
    }

    .td-col-amount {
      white-space: nowrap;
    }

    .td-col-action {
      width: 80px;
    }

    .td-col-percentage {
      width: 70px;
    }

    .informationTab {
      .custom-select {
        background-color: #fafbfc !important;
        background-image: url(/img/down-arrow.7b242252.svg) !important;
        background-repeat: no-repeat !important;
        background-size: 12px !important;
        font-size: 14px !important;
        min-height: 46px !important;
        padding: 0.375rem 1.75rem 0.375rem 0.75rem !important;
      }

      .ember-suggestions {
        width: 100% !important;
      }
    }

    .transaction_type_select {
      min-width: 150px;
    }
  }

  thead {
    background: #fff;

    th {
      vertical-align: middle;
      border-bottom: 1px dashed #cacaca;
      font-size: 14px;
    }
  }

  tbody {
    tr {
      border-bottom: 1px dashed #cacaca;
      font-size: 14px;
      &.invalid-row {
        background-color: rgb(239, 83, 80, 0.5);

        span.amount-expense,
        span.amount-income {
          color: black;
        }

        div.invalid-feedback {
          color: black;
        }
      }

      &.invalid-row:hover {
        background-color: rgb(239, 83, 80, 1);
      }

      &.no_suggestion td:nth-child(6) {
        @media screen and (max-width: 992px) {
          display: none !important;
        }
      }

      &.b-table-empty-row {
        background: #fff;
        font-weight: 600;
      }

      &:not(:first-child) {
        td {
          &:nth-child(6) div {
            @media screen and (max-width: 991px) {
              padding: 0;
              margin-left: 41%;
              width: 17%;
            }
          }

          &:nth-child(8) div {
            &:nth-child(1) {
              @media screen and (max-width: 991px) {
                width: 100%;
                padding: 0;
              }
            }

            &.actionDiv {
              @media screen and (max-width: 991px) {
                width: 22%;
                min-width: 119px;
                float: none;
                display: inline-block;
              }
            }
          }

          .cursor-help {
            cursor: help;
          }

          .titleValue {
            padding: 0;
            box-sizing: border-box;

            // margin-top: -2px;
            .titleText {
              display: inline;
            }

            .notify {
              display: block;
            }

            &:hover .titleButton {
              visibility: visible;
              display: inline !important;
            }

            .custom-inline-button {
              font-size: 80%;
            }
          }
          .titleButtonWrapper {
            display: inline;
          }

          .titleButton {
            visibility: hidden;
            display: inline;

            @media screen and (max-width: 692px) {
              width: 100%;
              float: left;
              margin-top: 5px;
            }

            .titleAction {
              display: inline;
              .info-2{
                &:hover {
                  color: blue;
                }
              }
              .edit-3 {
                &:hover {
                  color: #3bb37d;
                }
              }
              button {
                color: #000;

                &:hover {
                  color: #fff;
                }
              }

              .flaticon {
                &.solid {
                  &.edit-3 {
                    padding: 0;
                    // color: #0000ff;
                  }

                  &.trash-3 {
                    padding: 0;
                  }
                }
              }
            }
          }

          .ember-input {
            padding: 0;
            height: auto;
            min-height: initial;
            border-radius: 0;

            .placeholder {
              padding: 6px 10px;
            }

            .inputText {
              padding: 6px 10px;
            }

            .dropdownBtn {
              padding: 0px 3px !important;
            }

            /*border-bottom-color: #3cb373;*/
            &.active {
              &::after {
                padding: 16px 10px 10px 15px;
                line-height: 5px;
                background: transparent;
              }
            }

            &.inactive {
              &::after {
                padding: 10px 10px 16px 15px;
                line-height: 5px;
                background: transparent;
              }
            }
          }

          .custom-select {
            padding: 6px 32px 5px 5px;
            height: auto !important;
            background-color: transparent;
            border-radius: 0;

            &.is-valid {
              background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
                  no-repeat right 0.75rem center/8px 10px,
                #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
                  calc(0.75em + 0.375rem);
            }

            &.is-invalid {
              background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
                  no-repeat right 0.75rem center/8px 10px,
                #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
                  calc(0.75em + 0.375rem);
            }
          }

          &:not(:nth-child(5)) {
            .form-control {
              padding: 4px 5px 5px 5px;
              height: auto;
              background: #fff;
              border-radius: 0;

              @media screen and (min-width: 767px) {
                // max-width:140px; //let all fields be wider
              }
            }
          }

          &:nth-child(5) {
            @media screen and (min-width: 992px) {
              max-width: 300px;
              position: relative;
            }
          }
        }
      }

      td {
        border-top: none;
        vertical-align: middle;
        /*border-left:1px solid black;
          border-right:1px solid black;*/
        padding: 10px 7px 20px 7px;

        &:nth-child(1) {
          min-width: 20px;

          /* max-width: 20px; */
          &:hover {
            cursor: pointer;
          }

          .showDetailsMain {
            font-size: 16px;
            padding-left: 0;
            display: inline-block;
          }
        }

        .actionDiv {
          /*display: inline-block;*/
          float: right;

          @media screen and (max-width: 992px) {
            float: left;
          }
        }

        .invalid-feedback {
          position: absolute;
          margin: 0px;
          text-align: left;
          width: auto;
        }

        .vdp-datepicker__calendar {
          white-space: initial;

          @media screen and (max-width: 767px) {
            width: auto;
          }

          .cell {
            &.selected {
              background: transparent;
              color: #36b373;
              font-weight: bold;
            }

            &:hover {
              color: #36b373;
              border: 1px solid #fff !important;
            }
          }
        }

        .tableMultiselectDiv {
          @media screen and (min-width: 767px) {
            min-width: 270px;
          }
        }

        .ignore_error {
          padding: 5px 30px;
        }

        .percentageDiv {
          display: table;
          width: 100%;
          max-width: 80px;
          min-width: 80px;

          @media screen and (min-width: 768px) {
            float: right;
          }
        }

        .custom-select {
          font-size: 14px;
        }

        .percentageformgroup {
          min-width: 60px;
          max-width: 60px;
        }

        span {
          &.amount-expense {
            color: #da5b5b;
            font-weight: 500;

            &::before {
              content: "-";
            }
          }

          &.amount-income {
            color: #36b373;
            font-weight: 500;
          }

          &.amount-refund {
            color: #36b373;
            font-weight: 500;
          }

          &.amount-transfer {
            font-weight: 500;

            &.transferOther {
              color: #da5b5b;

              &::before {
                content: "-";
              }
            }

            &.transferSelf {
              color: #36b373;
            }
          }
        }

        .action-button-row {
          background: transparent;
          color: #0065ff;
          border: none;

          &.disabled {
            background: transparent;
            color: #fff;
            opacity: 0.4;

            &:hover {
              cursor: no-drop;
            }
          }

          &:active {
            background: none;
            color: #0065ff;
          }

          &:focus {
            border: none;
            background: none;
            color: #0065ff;
          }

          &.saveButton {
            padding: 0;
            border-radius: 0;
            margin: 0 5px 0 0;
          }

          &.saveButtonOk {
            background-color: #0065ff;
            padding: 5px 10px;
            color: #fff;

            .text-success {
              padding: 0;
              line-height: 10px;
              margin-right: 5px;
            }
          }

          &.cancelButton {
            background: transparent;
            color: #fff;
            padding: 0;
            border-radius: 0;
          }
        }

        .flaticon {
          &.checkmark {
            padding: 10px 5px;
            margin-left: 0;

            @media screen and (max-width: 992px) {
              padding: 0 5px;
            }
          }
        }

        .fa {
          &.fa-times {
            margin: 0;
            padding: 10px 5px;

            @media screen and (max-width: 992px) {
              padding: 0 5px;
            }
          }
        }

        .flaticon {
          &.solid {
            &.edit-3:hover {
              color: #3bb37d;
            }

            &.edit-3 {
              font-size: 13px;
              margin: 0;
              padding: 10px 5px;

              @media screen and (max-width: 992px) {
                padding: 0 5px;
              }
            }
          }

          &.share-3:hover {
            color: #3bb37d;
          }

          &.trash-3:hover {
            color: #ef5350;
          }

          &.trash-3 {
            font-size: 13px;
            margin: 0;
            padding: 10px 5px;

            @media screen and (max-width: 992px) {
              padding: 0 5px;
            }
          }
        }

        .input-group-append {
          position: absolute;
          right: 10px;

          @media screen and (max-width: 1440px) {
            right: 0px;
          }

          .input-group-text {
            padding: 0;
            background: transparent;
            border: none;
          }
        }

        .percentage_input {
          max-width: 67px;
          text-align: left;
          height: auto;
          font-size: 14px;
          padding: 0 10px 5px 1px;
          position: relative;
          background: transparent;

          &::after {
            content: "%";
            position: absolute;
          }
        }
      }

      &.b-table-details {
        td {
          padding: 10px;
        }
      }

      &.b-table-top-row {
        td {
          padding: 4px 4px 4px 15px;
          box-shadow: inset 0px 7px 16px -5px rgba(3, 3, 3, 0.23);

          .form-control {
            background: transparent;
            border: none;
          }
        }
      }

      &:hover {
        background: #fff;
      }

      &.no_suggestion {
        &:hover {
          background: transparent;
        }
      }
    }
  }
}
</style>
